











































































.positionDetail {
    .content {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
        @media (max-width:767px) {
            margin-top: .3rem;
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .titleSecond {
            color: #333333;
            font-size: 0.22rem;
            margin-bottom: 0.3rem;
        }

        .text {
            color: #333333;
            font-size: 0.2rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: justify;
        }
    }

    .content {
        color: #333333;
        font-size: 0.2rem;
        line-height: 2;
		@media (max-width:767px) {
			font-size: 0.4rem;
		}
        a {
            color: #ff1932;
        }
    }
}
